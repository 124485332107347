import React from 'react';
import type { AppProps } from 'next/app';
import { ThemeProvider } from 'styled-components';
import { AnimatePresence } from 'framer-motion';
import * as Sentry from '@sentry/browser';

import Version from 'u9/components/Version';

import GlobalStyles from 'utils/global-styles';
import theme from 'utils/theme';

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  dsn: process.env.SENTRY_DSN,
});

const App = ({ Component, pageProps, router }: AppProps) => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <AnimatePresence exitBeforeEnter={true} initial={false}>
      <Component key={router.route} router={router} {...pageProps} />
    </AnimatePresence>
    {process.env.ENV !== 'local' && process.env.ENV !== 'production' && (
      <Version />
    )}
  </ThemeProvider>
);

export default App;
