import { createGlobalStyle } from 'styled-components';

import resetCSS from 'utils/reset';

export default createGlobalStyle`
  ${resetCSS}

  html,
  body {
    font-family: 'Cutive Mono', monospace;
    font-size: 13px;
    padding: 0;
    margin: 0;
    background-color: #111;
    color: #999;
  }

  * {
    box-sizing: border-box;
  }
`;
